import React from 'react';
import { graphql } from 'gatsby';
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from '../lib/helpers';
import BlogPostPreviewGrid from '../components/blog-post-preview-grid';
import BlockContent from '../components/block-content';
import Container from '../components/container';
import GraphQLErrorList from '../components/graphql-error-list';
import ProjectPreviewGrid from '../components/project-preview-grid';
import SEO from '../components/seo';
import Layout from '../containers/layout';
import Icon from '../components/icons';

import '../styles/layout.css';
import styles from '../components/hero.css';

export const query = graphql`
	query IndexPageQuery {
		site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
			title
			description
			keywords
			twitter
			instagram
			email
			_rawHero
		}
		projects: allSanityProject(limit: 6, sort: { fields: [publishedAt], order: DESC }) {
			edges {
				node {
					id
					mainImage {
						crop {
							_key
							_type
							top
							bottom
							left
							right
						}
						hotspot {
							_key
							_type
							x
							y
							height
							width
						}
						asset {
							_id
						}
						alt
					}
					title
					_rawExcerpt
					slug {
						current
					}
				}
			}
		}
		posts: allSanityPost(limit: 6, sort: { fields: [publishedAt], order: DESC }) {
			edges {
				node {
					id
					publishedAt
					mainImage {
						crop {
							_key
							_type
							top
							bottom
							left
							right
						}
						hotspot {
							_key
							_type
							x
							y
							height
							width
						}
						asset {
							_id
						}
						alt
					}
					title
					_rawExcerpt
					slug {
						current
					}
				}
			}
		}
	}
`;

const IndexPage = (props) => {
	const { data, errors } = props;

	if (errors) {
		return (
			<Layout>
				<GraphQLErrorList errors={errors} />
			</Layout>
		);
	}

	const site = (data || {}).site;
	const postNodes = (data || {}).posts ? mapEdgesToNodes(data.posts).filter(filterOutDocsWithoutSlugs) : [];
	const projectNodes = (data || {}).projects ? mapEdgesToNodes(data.projects).filter(filterOutDocsWithoutSlugs) : [];

	if (!site) {
		throw new Error(
			'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
		);
	}

	return (
		<Layout>
			<SEO title="Designer" description={site.description} keywords={site.keywords} />
			<Container>
				<div className="hero">
					<img className="eye" src="/TattooEye.png" />
					<BlockContent className="heroText" blocks={site._rawHero || []} />
				</div>
				<p className="heroSubText">Currently iterating and innovating @ <a className="braze" href="https://www.braze.com" target="_blank" rel="noopener"><Icon symbol="braze" /></a>. Reach me by <a href="mailto:herrera.jeffrey@gmail.com">email</a> or <a href="https://www.twitter.com">twitter</a>!</p>
				{projectNodes && (
					<ProjectPreviewGrid title="Latest projects" nodes={projectNodes} browseMoreHref="/projects/" />
				)}
				{postNodes && (
					<BlogPostPreviewGrid title="Latest blog posts" nodes={postNodes} browseMoreHref="/blog/" />
				)}
			</Container>
		</Layout>
	);
};

export default IndexPage;
